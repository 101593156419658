import { CaptureConsole } from '@sentry/integrations';
import * as Sentry from '@sentry/vue';

import { getSentryConfig } from './sentry-utils.js';

function SentryInit(app, router) {
  const config = getSentryConfig();
  if (config) {
    try {
      Sentry.init({
        app,
        ...config,
        integrations: [
          new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          }),
          new CaptureConsole({ levels: ['error'] }),
        ],
      });
    } catch (err) {
      console.warn(err); // eslint-disable-line no-console
    }
  }
}
export default SentryInit;
